<template>
  <a-space class="TableSkeleton" direction="vertical" :size="Number(skeletonBottom)">
    <a-skeleton-button :active="true" v-for="item in skeletonNumber" :key="item"></a-skeleton-button>
  </a-space>
</template>

<script>
export default {
  name: 'TableSkeleton',
  props: {
    skeletonBottom: {
      type: [String, Number],
      default: 20,
    },
    skeletonNumber: {
      type: [String, Number],
      default: 8,
    },
  },
};
</script>

<style lang="less">
.TableSkeleton {
  width: 100%;

  .ant-skeleton-element {
    width: 100%;
    height: 70px;
  }

  .ant-skeleton-button {
    border-radius: 8px !important;
  }
}
</style>
