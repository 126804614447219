<template>
  <div class="contentView">
    <searchBox @emitSearch="emitSearch"></searchBox>
    <tableBox ref="bsearchBoxRef"></tableBox>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import searchBox from './cpn/returnSearch.vue';
import tableBox from './cpn/returnTable.vue';
    const bsearchBoxRef = ref();
    const emitSearch = (payload) => {
      bsearchBoxRef.value.action(payload);
    };
</script>
<style lang="less" scoped></style>
