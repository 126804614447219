import request from '@/utils/request'

/**
 * 发货单分页列表
 * http://yapi.wahool.com/project/29/interface/api/6131
 * 谢高仁
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function getDeliveryManageTableData(data = {}) {
  return request.GET('/supplier/shipping/page', data)
}

/**
 * 获取发货单信息
 * http://yapi.wahool.com/project/29/interface/api/6208
 * 谢高仁
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function getDeliveryManageDetail(data = {}) {
  return request.GET('/supplier/shipping/get', data)
}

/**
 * 添加快递
 * http://yapi.wahool.com/project/29/interface/api/6215
 * 谢高仁
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function createExpress(data = {}) {
  return request.POST('/supplier/shipping/createExpress', data)
}

/**
 * 确认发货
 * http://yapi.wahool.com/project/29/interface/api/6222
 * 谢高仁
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function confirmShipment(data = {}) {
  return request.POST('/supplier/shipping/confirmShipment', data)
}

/**
 * 导出发货单明细
 * http://yapi.wahool.com/project/29/interface/api/6334
 * 谢高仁
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function exportShipping(data = {}) {
  return request.GET('/supplier/shipping-detail/export', data, { responseType: 'blob' })
}

/**
 * 获取发货单信息
 * http://yapi.wahool.com/project/29/interface/api/6208
 * 谢高仁
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function getDeliveryManageDetailTableData(data = {}) {
  return request.GET('/supplier/shipping-detail/page', data)
}

/**
 * 发货单数量
 * http://yapi.wahool.com/project/29/interface/api/6488
 * 谢高仁
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function getDeliveryManageTotalsData(data = {}) {
  return request.GET('/supplier/shipping/totals', data)
}

//查看物流状态
export function expressLogsGet(data = {}) {
  return request.GET('/supplier/express-logs/get', data)
}
