import { transformColumnsI18n } from '@/language'
const tabNavList = [{
        title: 'returnManageDetail.table.pic',
        dataIndex: 'pic',
        key: 'pic',
        width: 90,
        slots: {
            customRender: 'pic',
        },
    },
    {
        title: 'returnManageDetail.table.size',
        dataIndex: 'size',
        key: 'size',
        width: 160,
        slots: {
            customRender: 'size',
        },
    },
    {
        title: 'returnManageDetail.table.returnNum',
        dataIndex: 'returnNum',
        key: 'returnNum',
        width: 100,
        slots: {
            customRender: 'returnNum',
        },
    },
    {
        title: 'returnManageDetail.table.purchasePrice',
        dataIndex: 'purchasePrice',
        key: 'purchasePrice',
        width: 130,
        slots: {
            customRender: 'purchasePrice',
        },
    },
    {
        title: 'returnManageDetail.table.returnPrice',
        dataIndex: 'returnPrice',
        key: 'returnPrice',
        width: 130,
        slots: {
            customRender: 'returnPrice',
        },
    },
    {
        title: 'returnManageDetail.table.defect',
        dataIndex: 'defect',
        key: 'defect',
        width: 120,
        slots: {
            customRender: 'defect',
        },
    },
    {
        title: 'returnManageDetail.table.code',
        dataIndex: 'code',
        key: 'code',
        width: 180,
        slots: {
            customRender: 'code',
        },
    },
    {
        title: 'returnManageDetail.table.orderSn',
        dataIndex: 'orderSn',
        key: 'orderSn',
        width: 240,
        slots: {
            customRender: 'orderSn',
        },
    },
    {
        title: 'returnManageDetail.table.returnReason',
        dataIndex: 'returnReason',
        key: 'returnReason',
        width: 120,
        slots: {
            customRender: 'returnReason',
        },
    },
    {
        title: 'returnManageDetail.table.returnReasonTypeName',
        dataIndex: 'returnReasonTypeName',
        key: 'returnReasonTypeName',
        width: 120,
        slots: {
            customRender: 'returnReasonTypeName',
        },
    },
]
console.log(transformColumnsI18n(tabNavList), 'aaaa-123123')
export const columns = transformColumnsI18n(tabNavList)