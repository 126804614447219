import request from '@/utils/request'
//退供应商列表
export function returnOrderPage(data = {}) {
  return request.GET('/supplier/return-order/page', data)
}
//退供应商数量汇总
export function returnOrderTotal(data = {}) {
  return request.GET('/supplier/return-order/total', data)
}
//获取操作日志列表
export function operationLogList(data = {}) {
  return request.GET('/supplier/operation-log/list', data)
}
//获取支付二维码
export function returnOrderPayInfo(data = {}) {
  return request.GET('/supplier/return-order/pay-info', data)
}
//退供应商明细 /return-order/get
export function returnOrderGetInfo(data = {}) {
  return request.GET('/supplier/return-order/get', data)
}
//获取退供应商列表
export function returnOrderDetailPage(data = {}) {
  return request.GET('/supplier/return-order-detail/page', data)
}
//退供应上同意/拒绝
export function returnOrderConfirm(data = {}) {
  return request.POST('/supplier/return-order/confirm', data)
}
//签收
export function returnOrderReceivedata(data = {}) {
  return request.POST('/supplier/return-order/receive', data)
}
//导出明细
export function returnOrderDetailDownload(data = {}) {
  return request.POST('/supplier/return-order-detail/download', data)
}
