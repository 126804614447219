import { transformColumnsI18n } from '@/language'

const columns = [{
        title: 'returnManage.table.returnNumber',
        dataIndex: 'returnOrder',
        key: 'returnOrder',
        width: 180,
        slots: {
            customRender: 'returnOrder',
        },
    },
    {
        title: 'returnManage.table.typeDesc',
        dataIndex: 'typeDesc',
        key: 'typeDesc',
        width: 130,
        slots: {
            customRender: 'typeDesc',
        },
    },
    {
        title: 'returnManage.table.waitHour',
        dataIndex: 'waitHour',
        key: 'waitHour',
        width: 100,
        slots: {
            customRender: 'waitHour',
        },
    },
    {
        title: 'returnManage.table.confirmAt',
        dataIndex: 'confirmAt',
        key: 'confirmAt',
        width: 150,
        slots: {
            customRender: 'confirmAt',
        },
    },
    {
        title: 'returnManage.table.returnGoodsQuantity',
        dataIndex: 'returnGoodsQuantity',
        key: 'returnGoodsQuantity',
        width: 100,
        slots: {
            customRender: 'returnGoodsQuantity',
        },
    },
    {
        title: 'returnManage.table.lastReturnAmount',
        dataIndex: 'lastReturnAmount',
        key: 'lastReturnAmount',
        width: 120,
        slots: {
            customRender: 'lastReturnAmount',
        },
    },
    {
        title: 'returnManage.table.expressFees',
        dataIndex: 'expressFees',
        key: 'expressFees',
        width: 120,
        slots: {
            customRender: 'expressFees',
        },
    },
    {
        title: 'returnManage.table.waitPayAmount',
        dataIndex: 'waitPayAmount',
        key: 'waitPayAmount',
        width: 150,
        slots: {
            customRender: 'waitPayAmount',
        },
    },
    {
        title: 'returnManage.table.processingStateDesc',
        dataIndex: 'processingStateDesc',
        key: 'processingStateDesc',
        width: 100,
        slots: {
            customRender: 'processingStateDesc',
        },
    },
    {
        title: 'returnManage.table.createAt',
        dataIndex: 'createAt',
        key: 'createAt',
        width: 200,
        slots: {
            customRender: 'createAt',
        },
    },
    {
        title: 'returnManage.table.logisticsInfor',
        dataIndex: 'logisticsInfor',
        key: 'logisticsInfor',
        width: 200,
        slots: {
            customRender: 'logisticsInfor',
        },
    },
    // 退货人
    // {
    //     title: 'returnManage.table.returnName',
    //     dataIndex: 'returnName',
    //     key: 'returnName',
    //     width: 150,
    //     slots: {
    //         customRender: 'returnName',
    //     },
    // },
    {
        title: 'returnManage.table.cancelUid',
        dataIndex: 'cancelUid',
        key: 'cancelUid',
        width: 150,
        slots: {
            customRender: 'cancelUid',
        },
    },
    {
        title: 'returnManage.table.remark',
        dataIndex: 'remark',
        key: 'remark',
        width: 150,
        slots: {
            customRender: 'remark',
        },
    },
    {
        title: 'returnManage.table.createAndOutbound',
        dataIndex: 'createAndOutbound',
        key: 'createAndOutbound',
        width: 260,
        slots: {
            customRender: 'createAndOutbound',
        },
    },
    {
        title: 'returnManage.table.createAndFinish',
        dataIndex: 'createAndFinish',
        key: 'createAndFinish',
        width: 260,
        slots: {
            customRender: 'createAndFinish',
        },
    },
    {
        title: 'returnManage.table.createAndCancel',
        dataIndex: 'createAndCancel',
        key: 'createAndCancel',
        width: 260,
        slots: {
            customRender: 'createAndCancel',
        },
    },
    {
        title: 'returnManage.table.operate', //operate
        key: 'operate',
        fixed: 'right',
        align: 'center',
        width: 210,
        slots: { customRender: 'operate' },
    },
]

export function returnColumn(index) {
    let strArr = []
    if (index == 10) {
        //待确认：退供单号、类型、等待时长、退货数量、退款金额（¥）、状态、创建时间、退货人、操作
        strArr = ['returnOrder', 'typeDesc', 'waitHour', 'returnGoodsQuantity', 'lastReturnAmount', 'processingStateDesc', 'createAt', 'operate']
    } else if (index == 20) {
        //待寄回：退供单号、类型、确认时间、退货数量、退款金额（¥）、创建时间、退货人、操作
        strArr = ['returnOrder', 'typeDesc', 'confirmAt', 'returnGoodsQuantity', 'lastReturnAmount', 'createAt', 'operate']
    } else if (index == 30) {
        //待签收：退供单号、类型、等待时长、退货数量、退款金额（¥）、运费（¥）、待退金额（¥)、退货人、物流信息、创建/出库时间、操作
        strArr = ['returnOrder', 'typeDesc', 'waitHour', 'returnGoodsQuantity', 'lastReturnAmount', 'expressFees', 'waitPayAmount', 'logisticsInfor', 'createAndOutbound', 'operate']
    } else if (index == 40) {
        //待付款：退供单号、类型、退货数量、退款金额（¥）、运费（¥）、待退金额（¥)、退货人、物流信息、创建/完成时间、操作
        strArr = ['returnOrder', 'typeDesc', 'returnGoodsQuantity', 'lastReturnAmount', 'expressFees', 'waitPayAmount', 'logisticsInfor', 'createAndFinish', 'operate']
    } else if (index == 80) {
        //已完成：退供单号、类型、退货数量、退款金额（¥）、运费（¥）、待退金额（¥)、退货人、物流信息、创建/完成时间、操作
        strArr = ['returnOrder', 'typeDesc', 'returnGoodsQuantity', 'lastReturnAmount', 'expressFees', 'logisticsInfor', 'createAndFinish', 'operate']
    } else if (index == 90) {
        //已取消：退供单号、类型、退货数量、退款金额（¥）、退货人、取消人、备注、创建/取消时间、操作
        strArr = ['returnOrder', 'typeDesc', 'returnGoodsQuantity', 'lastReturnAmount', 'cancelUid', 'remark', 'createAndCancel', 'operate']
    }
    const list = columns.filter((item) => {
        return strArr.includes(item.key)
    })
    return transformColumnsI18n(list)
        // return list
}