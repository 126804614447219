<template>
  <div class="contentView returnManageDetail">
    <div class="returnNavBox">
      <div class="tit">{{ t('returnManageDetail.pageTitle') }}</div>
      <!-- <span>退货仓：广州仓</span> -->
      <div class="navItems">
        <span># {{ detailObjRef.returnSupplierSn }}</span>
        <span>{{ t('returnManageDetail.navMessage.returnType') }}：{{ detailObjRef.typeDesc }}</span>
      </div>
      <!-- <div class="navItems">
        <div class="typeBox">申请类型：<span>{{detailObjRef.returnReasonTypeName}}</span></div>
        <div class="typeBox">退供原因：<span>{{detailObjRef.returnReason}}</span></div>
      </div> -->
      <div class="navItems">
        <div class="numBox">
          {{ t('returnManageDetail.navMessage.returnQuantity') }}:
          <span style="color: #ff6700">{{ detailObjRef.returnGoodsQuantity }}</span>
        </div>
        <div class="numBox">
          {{ t('returnManageDetail.navMessage.refundableAmount') }}:
          <span style="color: #ff6700">¥ {{ detailObjRef.lastReturnAmount }}</span>
        </div>
        <div class="numBox">
          {{ t('returnManageDetail.navMessage.freightAmount') }}:
          <span style="color: #ff6700">¥ {{ detailObjRef.expressFees }}</span>
        </div>
        <div class="numBox">
          {{ t('returnManageDetail.navMessage.amountRefunded') }}:
          <span style="color: #c72a29">¥ {{ detailObjRef.paidAmount }}</span>
        </div>
        <div class="numBox">
          {{ t('returnManageDetail.navMessage.amountPayable') }}:
          <span style="color: #c72a29">¥ {{ detailObjRef.waitPayAmount }}</span>
        </div>
        <!-- <div class="numBox">抵扣金额: <span style="color: #ff6700"> ¥6 </span></div> -->
      </div>
      <div class="addressBox">
        <span>{{ t('returnManageDetail.navMessage.recipients') }}：{{ detailObjRef.consignee }}</span>
        <span>{{ t('returnManageDetail.navMessage.phone') }}：{{ detailObjRef.phone }}</span>
        <span>
          {{ t('returnManageDetail.navMessage.address') }}：{{ detailObjRef.province }}{{ detailObjRef.city }}{{ detailObjRef.district }}{{ detailObjRef.address1
          }}{{ detailObjRef.address2 ? detailObjRef.address2 : '' }}
        </span>
        <span>{{ t('returnManageDetail.navMessage.postalCode') }}：{{ detailObjRef.postalCode }}</span>
      </div>
    </div>

    <div class="centerView flexS">
      <a-form ref="filterFormRef" :model="filterForm" layout="inline">
        <a-form-item name="goodsKeywords" style="width: 170px">
          <a-input v-model:value.trim="filterForm.goodsKeywords" allowClear size="large" :placeholder="t('returnManageDetail.filterForm.goodsKeywords')" />
        </a-form-item>
        <a-form-item name="purchaseSnKeywords" style="width: 170px">
          <a-input v-model:value.trim="filterForm.purchaseSnKeywords" allowClear size="large" :placeholder="t('returnManageDetail.filterForm.purchaseSnKeywords')" />
        </a-form-item>
        <a-form-item name="defectKeywords" style="width: 170px">
          <a-input v-model:value.trim="filterForm.defectKeywords" allowClear size="large" :placeholder="t('returnManageDetail.filterForm.defectKeywords')" />
        </a-form-item>
        <a-form-item>
          <a-space :size="16">
            <a-button type="primary" size="large" @click="search">{{ t('search') }}</a-button>
            <div class="form-item-button gray" @click="reset">{{ t('reset') }}</div>
          </a-space>
        </a-form-item>
      </a-form>
      <a-space :size="10" style="float: right; padding-bottom: 10px">
        <!-- 0 待提交 10 待确认 20 待寄回  30 待签收 40 待付款 80 已完成 90 已取消 -->
        <!-- 待确认：同意、拒绝、导出明细 -->
        <!-- 待寄回：导出明细 -->
        <!-- 待签收：确认签收、支付、导出明细 -->
        <!-- 待付款：支付、导出明细 -->
        <!-- 已完成：导出明细 -->
        <!-- 已取消：导出明细 -->
        <template v-if="detailObjRef.state == 10">
          <a-button size="large" type="primary" :loading="confirmShipmentSubmitting" @click="agreeFn">{{ t('returnManageDetail.btns.agree') }}</a-button>
          <div class="form-item-button gray refuseBtn" @click="refuseClick">{{ t('returnManageDetail.btns.refuse') }}</div>
        </template>
        <template v-if="detailObjRef.state == 30">
          <a-button size="large" type="primary" @click="signForClick">{{ t('returnManageDetail.btns.confirmReceipt') }}</a-button>
          <div class="form-item-button gray refuseBtn" @click="paymentClick">{{ t('returnManageDetail.btns.payment') }}</div>
        </template>
        <template v-if="detailObjRef.state == 40">
          <div class="form-item-button gray refuseBtn" @click="paymentClick">{{ t('returnManageDetail.btns.payment') }}</div>
        </template>
        <a-button size="large" type="primary" @click="importClick">{{ t('returnManageDetail.btns.download') }}</a-button>
      </a-space>
    </div>

    <div class="centerView">
      <a-spin tip="Loading..." :spinning="spinRef">
        <a-table
          v-if="!tableLoading"
          :dataSource="dataSource"
          :columns="columns"
          :loading="tableLoading"
          :pagination="false"
          row-key="id"
          size="small"
          class="ant-table-striped"
          :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          :scroll="{ x: 1000 }"
        >
          <template #pic="{ record }">
            <ImgGallery :urls="[record.picTemplate]" :img-width="50" :img-height="62" :show-img-num="1" :show-more="false" />
          </template>
          <template #size="{ record }">
            <div>SKU: {{ record.sku }}</div>
            <div>{{ t('returnManageDetail.tableList.compilation') }}: {{ record.manufacturingNo }}</div>
            <div>{{ t('returnManageDetail.tableList.attribute') }}: {{ record.color }}/{{ record.size }}</div>
          </template>
          <template #returnNum="{ record }">
            <div>{{ t('returnManageDetail.tableList.returnable') }}：{{ record.quantity }}</div>
            <div>{{ t('returnManageDetail.tableList.retreat') }}：{{ record.shippedQuantity }}</div>
          </template>
          <template #purchasePrice="{ record }">{{ record.purchasePrice }}</template>
          <template #returnPrice="{ record }">
            <div>{{ t('returnManageDetail.tableList.needToReturn') }}：{{ record.refundAmount }}</div>
            <div>
              {{ t('returnManageDetail.tableList.returnablePrice') }}：
              <span :style="{ color: record.refundAmount !== record.realRefundAmount ? '#f00' : '' }">{{ record.realRefundAmount }}</span>
            </div>
          </template>
          <template #defect="{ record }">
            <div>
              <span v-for="(item, index) in JSON.parse(record.defectList)" :key="index">{{ item }}</span>
            </div>
            <div>{{ t('returnManageDetail.modalTit.defectRemark') }}:{{ record.defectRemark || '--' }}</div>
            <div style="text-decoration: underline; color: #1890ff; cursor: pointer" @click="showDetail(record)">{{ t('returnManageDetail.tableList.showDetail') }}</div>
            <!-- <div>质检时间：2023-03-01 12:32:23</div> -->
          </template>
          <template #code="{ record }">{{ record.sku }}-{{ record.inventorySn }}</template>
          <template #orderSn="{ record }">
            <div>{{ t('returnManageDetail.tableList.purchase') }}：{{ record.purchaseSn }}</div>
            <div>{{ t('returnManageDetail.tableList.channel') }}：{{ record.channelSn }}</div>
            <!-- <div>类型：外采</div> -->
          </template>
        </a-table>
      </a-spin>
      <TableSkeleton v-if="tableLoading" />
      <pagination mt lable="" @change="onPageChange" :data="tableResult" @sizeChange="onSizeChange"></pagination>
    </div>
    <!-- 同意退回 -->
    <CustomModal
      modalWidth="380px"
      v-model:modalVisible="agreeModalVisible"
      :modalBodyTitle="t('returnManageDetail.modalTit.agreeTit')"
      :modalBodyTips="t('returnManageDetail.modalTit.agreeDesc')"
      :modalClosable="false"
      :modalCancelVisible="true"
      modalFooterJustify="center"
      @submit="agreeModelSubmit"
    />
    <!-- 确认签收 -->
    <CustomModal
      modalWidth="380px"
      v-model:modalVisible="signForVisible"
      :modalBodyTitle="t('returnManageDetail.modalTit.confirmReceiptTit')"
      :modalBodyTips="t('returnManageDetail.modalTit.confirmReceiptDesc')"
      :modalClosable="false"
      :modalCancelVisible="true"
      modalFooterJustify="center"
      :submitting="confirmShipmentSubmitting"
      @submit="signForSubmit"
    />
    <!-- 支付弹框 -->
    <amodalPayment ref="modalPayref" />
    <!-- 拒绝 -->
    <a-modal class="modalPay" v-model:visible="refuseVisible" :title="t('returnManageDetail.modalTit.refuseTit')" @ok="handleRefuseOk">
      <a-textarea v-model:value="refuseValue" :placeholder="t('returnManageDetail.modalTit.refusePlaceholder')" style="margin-bottom: 30px" />
    </a-modal>
    <!-- 质检详情 -->
    <div v-if="inspectionVisible">
      <a-modal class="modalPay" v-model:visible="inspectionVisible" :title="t('returnManageDetail.modalTit.qualityTit')" :footer="null" @cancle="handleCancle">
        <div class="codeBox">
          <span>{{ t('returnManageDetail.modalTit.uniqueCode') }}：{{ detailRef.sku }}-{{ detailRef.inventorySn }}</span>
        </div>
        <div class="defect">
          <div class="top">
            {{ t('returnManageDetail.modalTit.defect') }}：
            <span v-for="(item, index) in JSON.parse(detailRef.defectList)" :key="index">{{ item }}</span>
          </div>
          <div class="top">{{ t('returnManageDetail.modalTit.defectRemark') }}：{{ detailRef.defectRemark }}</div>
          <div class="bot">
            <div v-for="(item, index) in JSON.parse(detailRef.imageList)" :key="index">
              <a-popover placement="rightTop">
                <template #content>
                  <img class="ImgGallery-popover-img" :style="popoverImgStyle" :src="formatImgFn(item)" />
                </template>
                <img class="ImgGallery-img" :style="imgStyle" :src="formatImgFn(item)" />
              </a-popover>
            </div>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script setup>
  /* eslint-disable */
  import { ref, reactive, watch, defineExpose, defineEmits } from 'vue'
  import pagination from '@/components/pagination'
  import { message } from 'ant-design-vue'
  import TableSkeleton from '@/views/supplier/components/TableSkeleton'
  import ImgGallery from '@/views/supplier/components/ImgGallery'
  import CustomModal from '@/views/supplier/components/CustomModal'
  import amodalPayment from './cpn/amodalPayment.vue'
  // import inspectionDetails from './cpn/inspectionDetails.vue'
  import { returnOrderGetInfo, returnOrderDetailPage, returnOrderConfirm, returnOrderReceivedata, returnOrderDetailDownload } from '@/api/supplier/returnSupplier.js'
  import { createExportTask, getTaskResult } from '@/api/supplier/data-workers.js'
  import { useI18n } from 'vue-i18n'
  import { columns } from './config/returnDetail'
  import axios from 'axios'
  import { formatImg } from '@/utils/util.js'
  const formatImgFn = (path, w = 500, h = 500, bw = 500, by = 500) => formatImg(path, w, h, bw, by)
  const { t, locale } = useI18n()
  const inspectionVisible = ref(false) //质检详情
  const spinRef = ref(false) //控制loading
  const signForVisible = ref(false) //签收确认弹框
  const filterFormRef = ref() //绑定搜索框
  const modalPayref = ref() //支付弹框元素绑定
  const agreeModalVisible = ref(false) //同意弹框二次确认
  const confirmShipmentSubmitting = ref(false) //同意弹框二次确认loading
  const refuseVisible = ref(false) //拒绝弹框
  const refuseValue = ref('') //拒绝原因
  const detailRef = ref({}) //质检详情
  const downloadToken = ref() //导出明细token
  const detailObjRef = ref({})
  const page = ref({
    page: 1,
    size: 10
  })
  const emit = defineEmits(['closeModal'])
  const filterForm = reactive({
    goodsKeywords: '', // spu/编码/SKU
    purchaseSnKeywords: '', //采购单/渠道单号
    defectKeywords: '' //瑕疵点
  })
  const tableLoading = ref(false)
  const dataSource = ref([])
  const tableResult = ref(null)

  //详情数据获取
  const getDetail = id => {
    returnOrderGetInfo({ id }).then(res => {
      console.log(res, '获取详情数据信息')
      detailObjRef.value = res.data
    })
  }
  //获取退供应商列表详情
  const getDetailList = (returnSupplierSn = '') => {
    let payload = {
      ...filterForm,
      returnSupplierSn: returnSupplierSn ? returnSupplierSn : detailObjRef.value.returnSupplierSn,
      ...page.value
    }
    tableLoading.value = true
    returnOrderDetailPage(payload).then(res => {
      console.log(res, '退供应商列表详情')
      tableResult.value = res.data
      dataSource.value = res.data.data
      tableLoading.value = false
    })
  }
  //点击签收显示弹框
  const signForClick = () => {
    signForVisible.value = true
  }
  //签收二次确认弹框
  const signForSubmit = () => {
    returnOrderReceivedata({ returnSupplierSn: detailObjRef.value.returnSupplierSn }).then(res => {
      console.log(res, '签收确认弹框')
      if (res.code == 200) {
        message.success('签收成功')
        signForVisible.value = false
        emit('closeModal')
      }
    })
  }
  //查看质检详情
  const showDetail = item => {
    console.log(item, 'item-123')
    detailRef.value = item
    inspectionVisible.value = true
  }
  //点击支付
  const paymentClick = () => {
    modalPayref.value.action({ id: detailObjRef.value.id, returnSupplierSn: detailObjRef.value.returnSupplierSn })
  }
  //点击拒绝
  const refuseClick = () => {
    refuseVisible.value = true
  }
  //拒绝提交接口
  const handleRefuseOk = () => {
    returnOrderConfirm({
      returnSupplierSn: detailObjRef.value.returnSupplierSn,
      rejectReason: refuseValue.value,
      confirmType: 'REJECT'
    }).then(res => {
      console.log(res, '同意退回按钮操作')
      if (res.code == 200) {
        refuseVisible.value = false
        refuseValue.value = ''
        message.success('操作成功')
        emit('closeModal')
      }
    })
  }
  //导出明细
  const importClick = async () => {
    downloadToken.value = ''
    let selelist = {
      returnSupplierSn: [detailObjRef.value.returnSupplierSn],
      ...page.value,
      ...filterForm
    }
    let payload = {
      projectCode: 'SUPPLIER_RETURN_ORDER_DETAIL_EXPORT_NEW',
      dataParam: JSON.stringify(selelist)
    }
    const { code, data } = await createExportTask(payload)
    if (code == 200) downloadToken.value = data
    await getTaskResultFn(data)
  }
  const countNum = ref('')
  //下载列表轮训
  const getTaskResultFn = () => {
    getTaskResult({ taskToken: downloadToken.value })
      .then(res => {
        if (res.code == 200) {
          // 任务状态
          //   0、待处理
          //   1、成功
          //   9、失败
          if (countNum.value > 5 && res.data.state == 0) {
            countNum.value = 0
            spinRef.value = false
            return message.error('下载失败，请稍后重试')
          }
          switch (res.data.state) {
            case 0:
              countNum.value++
              setTimeout(getTaskResultFn, 2000)
              break
            case 1:
              countNum.value = 0
              spinRef.value = false
              res.data.fileUrl && (window.location.href = res.data.fileUrl)
              break
            case 9:
              countNum.value = 0
              spinRef.value = false
              message.error(res.data.resultMessage || '下载失败')
              break
            default:
              break
          }
        }
      })
      .catch(() => {
        countNum.value = 0
        spinRef.value = false
      })
  }
  //重置
  const reset = () => {
    filterFormRef.value.resetFields()
    page.value.page = 1
    getDetailList()
  }
  //搜索
  const search = () => {
    page.value.page = 1
    getDetailList()
  }
  //分页变化
  const onPageChange = e => {
    page.value.page = e
    getDetailList()
  }
  const onSizeChange = ({ pageSize }) => {
    page.value.size = pageSize
    page.value.page = 1
    getDetailList()
  }

  /* 同意退回弹框显示 */
  const agreeFn = () => {
    agreeModalVisible.value = true
  }
  //同意退回按钮操作
  function agreeModelSubmit() {
    confirmShipmentSubmitting.value = true
    returnOrderConfirm({
      returnSupplierSn: detailObjRef.value.returnSupplierSn,
      confirmType: 'AGREE'
    })
      .then(res => {
        console.log(res, '同意退回按钮操作')
        confirmShipmentSubmitting.value = false
        if (res.code == 200) {
          agreeModalVisible.value = false
          message.success('操作成功')
          emit('closeModal')
        }
      })
      .catch(() => {
        confirmShipmentSubmitting.value = false
      })
  }
  defineExpose({ getDetail, getDetailList })
</script>

<style lang="less" scoped>
  .ImgGallery-img {
    width: 85px;
    height: 75px;
    margin-right: 10px;
  }
  .ImgGallery-popover-img {
    width: 500px;
    height: 500px;
  }
  .codeBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .defect {
    margin-top: 12px;
    .top {
      font-size: 16px;
      font-weight: bold;
      span {
        font-weight: 400;
      }
    }
    .bot {
      margin-top: 12px;
      display: flex;
    }
  }
  .refuseBtn {
    background: #c72a29;
    color: #fff;
    text-align: center;
  }
  .refuseBtn:hover {
    background: rgba(199, 42, 41, 0.8);
  }
  .addressBox {
    margin: 10px 0;
    span {
      font-size: 16px;
      color: #ff6700;
      margin-right: 20px;
    }
  }
  .returnNavBox {
    padding: 0px 30px;
    .tit {
      font-size: 24px;
      color: #131011;
    }
    .navItems {
      margin: 10px 0;
      span {
        font-size: 16px;
        color: #131011;
        margin-right: 30px;
      }
      .typeBox {
        display: inline-block;
        font-size: 16px;
        color: #333;
        margin-right: 30px;
        span {
          font-size: 14px;
          font-weight: 400;
        }
      }
      .numBox {
        display: inline-block;
        font-size: 16px;
        color: #333;
        font-weight: bold;
        margin-right: 30px;
      }
    }
  }
  .returnManageDetail {
    position: relative;
    font-family: 'Heiti SC';
    .centerView {
      // margin-bottom: 15px !important;
    }
    .select {
      margin: 0 !important;
    }

    .order-info-wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 30px;
    }
    .address-info-wrap {
      display: flex;
      .address-info-img {
        display: inline-block;
        width: 80px;
        height: 80px;
      }
      .address-info-detail {
        margin-left: 20px;
        color: #131011;
        font-size: 16px;
        line-height: 27px;
      }
      .address-info-order-code {
        font-size: 24px;
        font-weight: 500;
        line-height: 1;
        margin-bottom: 10px;
      }
    }
    .logistics-info-right {
      height: 42px;
      background-color: #fafafa;
      border-radius: 8px;
      padding: 0 16px;
      color: #989495;
      font-size: 14px;

      .logistics-info-icon {
        width: 24px;
      }

      .label {
        color: #131011;
        font-weight: 400;
        font-size: 16px;
        margin: 0 20px;
      }
    }
  }
</style>
