<template>
  <div class="OperationLogModal">
    <CustomModal v-model:modalVisible="visible" :modalTitle="modalTitle || t('components.OperationLogModal.title')" :modalFooterVisible="false">
      <a-timeline :reverse="true" class="OperationLogModal-timeline">
        <a-timeline-item v-for="(item, index) in data" :key="index">
          <template #dot>
            <span class="OperationLogModal-dot" :class="{ current: index == data.length - 1 }"></span>
          </template>
          <div class="timeline-item-header">
            <span class="date">{{ item.operatorAt }}</span>
            <span class="name">{{ item.operatorName }}</span>
          </div>
          <div class="timeline-item-body">
            {{ item.operationMsg }}
          </div>
          <div class="timeline-item-footer"></div>
        </a-timeline-item>
      </a-timeline>
    </CustomModal>
  </div>
</template>

<script>
/* eslint-disable */
import { ref, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import CustomModal from '@/views/supplier/components/CustomModal';
export default {
  name: 'OperationLogModal',
  components: {
    CustomModal,
  },
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: [],
    },
  },
  emits: ['update:modalVisible'],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const visible = ref(false);
    watch(
      () => props.modalVisible,
      (newValue) => {
        visible.value = newValue;
      }
    );
    watch(
      () => visible.value,
      (newValue) => {
        emit('update:modalVisible', newValue);
      }
    );
    return {
      t,
      visible,
    };
  },
};
</script>

<style lang="less">
.OperationLogModal-timeline {
  .ant-timeline-item-tail {
    border-left-color: #d9d9d9;
  }

  .ant-timeline-item-head-custom {
    padding: 0;
  }
  .OperationLogModal-dot {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #d9d9d9;
    vertical-align: bottom;

    &.current {
      width: 20px;
      height: 20px;
      background-color: #ff6700;
    }
  }

  .ant-timeline-item-content {
    margin-left: 30px;
  }
  .timeline-item-header {
    line-height: 2;

    .name {
      margin-left: 23px;
    }
  }
  .timeline-item-body {
    padding: 10px 12px;
    background-color: #F7F7F7;
    border-radius: 8px;
  }
}
</style>
