<template>
  <div class="centerView returnSearch">
    <a-form :model="formData" :rules="rules" ref="formRef" layout="inline">
       <a-row>
        <a-col :span="4">
          <a-form-item name="goodsKeywords">
            <a-input allowClear size="large" v-model:value="formData.goodsKeywords" :placeholder="t('returnManage.filterForm.name')"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item name="expressCode">
            <a-input allowClear size="large" v-model:value="formData.expressCode" :placeholder="t('returnManage.filterForm.expressCode')"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item name="paymentStartTime" style="margin-right: 14px">
            <a-range-picker v-model:value="formData.date" :format="['YYYY-MM-DD', 'YYYY-MM-DD']" valueFormat="YYYY-MM-DD" :placeholder="['Begin Time', 'End Time']" size="large">
              <template #suffixIcon>
                <img style="width: 15px; height: 15px; display: flex" src="@/assets/images/table/timePickIcon.png" alt="" />
              </template>
            </a-range-picker>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item name="returnSupplierSn">
            <a-input allowClear size="large" v-model:value="formData.returnSupplierSn" :placeholder="t('returnManage.filterForm.returnNumber')"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item>
            <a-space :size="16">
              <a-button type="primary" size="large" @click="search">{{ t('search') }}</a-button>
              <div class="form-item-button gray" @click="reset">{{ t('reset') }}</div>
            </a-space>
          </a-form-item>
        </a-col>
       </a-row>
      <!-- <a-form-item name="personReturn" style="width: 90px">
        <a-select allowClear size="large" v-model:value="formData.personReturn" :placeholder="t('returnManage.filterForm.returnName')">
          <a-select-option v-for="item in warehouseListRefList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item> -->
    </a-form>
  </div>
</template>
<script setup>
import { ref, reactive,defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'
//引入第三方方法插件
import moment from 'moment';
const { t } = useI18n()
const formRef = ref()
//表单校验
const rules = []
const formData = reactive({
  goodsKeywords: null, //名称/编码/SKU
  returnSupplierSn: null, //退供单号
  expressCode:null,//快递单号
  date: [], //时间
  // personReturn: null, //退货人
})

// const warehouseListRefList = ref([//退货人列表
//   { id: 1, name: 'a' },
//   { id: 2, name: 'b' },
//   { id: 3, name: 'c' },
// ])
const emit = defineEmits(['emitSearch'])
//搜索事件
const search = () => {
  console.log('搜索事件')
  if (formData.date[0]) {
    formData.createAtStart = moment(formData.date[0]).format('YYYY-MM-DD');
    formData.createAtEnd = moment(formData.date[1]).format('YYYY-MM-DD');
  }
  console.log(formData, 'formData---formData');
  const payload = JSON.parse(JSON.stringify(formData));
  delete payload['date'];
  console.log(payload, 'payload--payload');
  emit('emitSearch',payload)
}
//重置事件
const reset = () => {
  formRef.value.resetFields()
  if (formData.date[0]) {
    formData.date = []
  }
  emit('emitSearch')
}
</script>
<style lang="less">
.returnSearch {
}
</style>
