<template>
  <div>
    <a-modal class="modalPay" v-model:visible="visible" title="支付窗口" :footer="null" @cancle="handleCancle">
      <div class="divBox">
        <div style="width: 300px">
          <div class="mentOrder">退供单：{{orderObjRef.returnSupplierSn}}</div>
          <div class="price">待付金额: <span>{{returnPrice.waitPayAmount}}</span></div>
          <div class="priceTit">支付成功后截图给财务，做回款登记记录</div>
          <div class="priceType">支付方式：</div>
        </div>
        <div>
          <img class="imgPic" :src="returnPrice.paymentQRCodeLink" alt="" />
        </div>
      </div>
    </a-modal>
  </div>
</template> 
<script setup>
import { ref, defineExpose } from 'vue'
import { returnOrderPayInfo } from '@/api/supplier/returnSupplier.js'
const visible = ref(false)
const orderObjRef = ref({})//订单信息
const returnPrice = ref({})//待付款信息
const action = (orderObj) => {
  orderObjRef.value = orderObj
  returnOrderPayInfo({id:orderObj.id}).then(res=>{
    console.log(res,'获取支付二维码');
    if(res.code == 200){
      returnPrice.value = res.data
      visible.value = true
    }
  })
}
const handleCancle = () => {
  orderObjRef.value = {}
  visible.value = false
}
defineExpose({ action })
</script>
<style lang="less" scoped>
.divBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div {
  margin: 10px 0;
}
.mentOrder {
  font-size: 16px;
  color: #333;
}
.price {
  font-size: 20px;
  color: #ff6700;
  font-weight: bold;
  span {
    color: #c72a29;
    font-weight: bold;
  }
}
.priceTit {
  color: #f00;
}
.priceType {
  font-size: 18px;
  color: #000;
  font-weight: bold;
}
.imgPic {
  width: 260px;
}
</style>
