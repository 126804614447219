<template>
  <div class="centerView returnTable">
    <div class="manageNav">
      <a-tabs v-model:activeKey="activeKey" @change="tabsChange" size="small">
        <a-tab-pane :key="item.key" :tab="t(item.title) + `(${item.num})`" v-for="item in tabList"></a-tab-pane>
      </a-tabs>
      <div class="btnExport">
        <a-button type="primary" size="large" :disabled="selectedRowKeysList.length > 0 ? false : true" @click="exportClick">导出订单明细</a-button>
      </div>
    </div>

    <div class="tableBoxs" v-if="!tableLoading">
      <a-spin tip="Loading..." :spinning="spinRef">
        <a-table :dataSource="tableData" :columns="columns" :pagination="false" size="small" :row-key="(r) => r.returnSupplierSn" :scroll="{ x: 1000 }" :row-selection="rowSelection">
          <!-- 操作 -->
          <template #operate="{ record }">
            <a-space :size="20">
              <!-- 待确认：查看详情、日志记录 -->
              <!-- 待寄回：日志记录 -->
              <!-- 待签收：查看详情、支付 -->
              <!-- 待付款：支付、日志记录 -->
              <!-- 已完成：日志记录 -->
              <!-- 已取消：日志记录 -->
              <a-button type="primary" class="logsBtn" v-if="[10, 20, 40, 80, 90].includes(activeKey)" @click="showLogsClick(record.id)">日志记录</a-button>
              <a-button type="primary" class="logsBtn" @click="toDetail(record)" v-if="[10, 30].includes(activeKey)">查看详情</a-button>
              <a-button type="primary" v-if="[30, 40].includes(activeKey)" @click="paymentClick(record)">支付</a-button>
            </a-space>
          </template>
          <!-- 退供单号 -->
          <template #returnOrder="{ record }">
            <span style="color: #050505; text-decoration: underline; cursor: pointer" @click="toDetail(record)">{{ record.returnSupplierSn }}</span>
          </template>
          <!-- 等待时长 -->
          <template #waitHour="{ record }">
            <div style="color: #f00" v-if="record.waitHour > 72">
              <span style="margin-right: 6px">{{ record.waitHour }}</span
              >H
            </div>
            <div v-else>
              <span style="margin-right: 6px">{{ record.waitHour }}</span
              >H
            </div>
          </template>
          <!-- 状态 -->
          <!-- <template #state>
          <span style="color: #f00">拒绝</span>
        </template> -->
          <!-- 物流信息 -->
          <template #logisticsInfor="{ record }">
            <div v-if="record.expressListBoList.length > 0">
              <div class="addCode" v-for="item in record.expressListBoList" :key="item.id" @click="showAddressMOdal(item.expressCode)">{{ item.expressCode }}[{{ item.expressName }}]</div>
            </div>
            <div v-else>---</div>
          </template>
          <!-- 创建/出库时间 -->
          <template #createAndOutbound="{ record }">
            <div>创建时间：{{ record.createAt }}</div>
            <div>出库时间：{{ record.shippedAt }}</div>
          </template>
          <!-- 创建/完成时间 -->
          <template #createAndFinish="{ record }">
            <div>创建时间：{{ record.createAt }}</div>
            <div>完成时间：{{ record.completeAt }}</div>
          </template>
          <!-- 创建/取消时间 -->
          <template #createAndCancel="{ record }">
            <div>创建时间：{{ record.createAt }}</div>
            <div>取消时间：{{ record.cancelAt }}</div>
          </template>
        </a-table>
      </a-spin>
      <pagination mt lable="" :data="list" @change="onPageChange" @sizeChange="sizeChange"></pagination>
    </div>
    <TableSkeleton v-if="tableLoading" />
    <a-drawer v-model:visible="logsVisible" class="custom-class" style="color: red" title="操作日志记录" placement="right">
      <div class="itemLogs" v-for="item in logsList" :key="item.id">
        <div class="drawTop">
          <div>
            <span class="radiusBox"></span>
            <span>{{ item.operatorAt }}</span>
          </div>
          <span>{{ item.operatorName }}</span>
        </div>
        <div class="drawBot">{{ item.operationMsg }}</div>
      </div>
    </a-drawer>
    <!-- 日志 -->
    <OperationLogModal v-model:modalVisible="operationLogModalVisible" />
    <!-- 支付弹框 -->
    <amodalPayment ref="modalPayref" />
    <a-drawer v-model:visible="detailVisible" width="90% !important" class="custom-class" title="" placement="right">
      <detailPage ref="detailRef" @closeModal="closeModal" />
    </a-drawer>
  </div>
  <a-modal title="物流信息" v-model:visible="addressVisible" :footer="null">
    <a-timeline>
      <a-timeline-item v-for="(item, index) in logistics" :key="index">
        <div class="nodeTime">{{ item.nodeTime }}</div>
        <div><GlobalOutlined style="margin-right: 10px" />{{ item.nodeLocation }}</div>
        <div class="nodeDesc">{{ item.nodeDesc }}</div>
        <!-- <p>Solve initial network problems 3 2015-09-01</p> -->
      </a-timeline-item>
    </a-timeline>
  </a-modal>
</template>
<script setup>
/* eslint-disable */
import { ref, watch, onActivated, defineExpose, computed, unref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import pagination from '@/components/pagination.vue'
import TableSkeleton from '@/views/supplier/components/TableSkeleton'
import OperationLogModal from '@/views/supplier/components/OperationLogModal'
import detailPage from '../returnManageDetail.vue'
import amodalPayment from './amodalPayment.vue'
import { formatImg } from '@/utils/util.js'
import { returnColumn } from '../config/returnTable'
import { expressLogsGet } from '@/api/supplier/orderManagement/deliveryManage'
import { returnOrderTotal, returnOrderPage, operationLogList } from '@/api/supplier/returnSupplier.js'
import { createExportTask, getTaskResult } from '@/api/supplier/data-workers'
const { t, locale } = useI18n()
const route = useRoute()
//图片地址案例
const url = ref([
  'https://video.wahool.com/goods/2022-08-31/2610ac9572d659ada87f4e333f691eef-3471f9c9214dd00cad122c4222d95e73.png?x-oss-process=image/resize,m_lfit,h_{height},w_{width},color_FFFFFF,image/format,jpg',
])
console.log(t('selectShop'))
const detailVisible = ref(false) //详情弹框
const detailRef = ref() //详情组件绑定
const modalPayref = ref() //支付弹框元素绑定
const activeKey = ref(10) //tab绑定
const logsList = ref([]) //日志记录数据列表
const payloadObj = ref({})
const operationLogModalVisible = ref(false) //操作日志
const tableLoading = ref(false)
const logsVisible = ref(false) //日志记录
const agreeModalVisible = ref(false) //二次确认弹框
const columns = ref([]) //表格绑定标题
//tabs数据 10 待确认 20 待寄回 30 待签收 40 待付款 80 已完成 90 已取消
const tabList = ref([
  { key: 10, title: 'returnManage.tabsPane.1', name: 'waitConfirmTotal', num: '0' },
  { key: 20, title: 'returnManage.tabsPane.2', name: 'waitShippingTotal', num: '0' },
  { key: 30, title: 'returnManage.tabsPane.3', name: 'waitReceiveTotal', num: '0' },
  { key: 40, title: 'returnManage.tabsPane.4', name: 'waitPayTotal', num: '0' },
  { key: 80, title: 'returnManage.tabsPane.5', name: 'completeTotal', num: '0' },
  { key: 90, title: 'returnManage.tabsPane.6', name: 'canceledTotal', num: '0' },
])
//表格数据
const tableData = ref([
  { id: 1, key: 1 },
  { id: 2, key: 2 },
  { id: 3, key: 3 },
  { id: 4, key: 4 },
])
const list = ref({}) //表格分页
const page = ref({
  //分页数据
  page: 1,
  size: 10,
})
columns.value = returnColumn(activeKey.value)
//初始化方法
const action = (payload) => {
  payloadObj.value = payload
  page.value.page = 1
  getDataList()
}
//获取tabs数据汇总
const getReturnOrderTotal = (payload) => {
  returnOrderTotal(payload).then((res) => {
    console.log(res, '获取列表数据汇总')
    Object.keys(res.data).forEach((item) => {
      tabList.value.map((val) => {
        if (item == val.name) {
          val.num = res.data[item]
        }
      })
    })
  })
}
//获取表格数据
const getDataList = () => {
  let payload = {
    ...payloadObj.value,
    ...page.value,
    state: activeKey.value,
  }
  tableLoading.value = true
  getReturnOrderTotal(payload)
  returnOrderPage(payload).then((res) => {
    console.log(res, '获取列表数据')
    tableData.value = res.data.data
    list.value = res.data
    tableLoading.value = false
  })
}
getDataList()
//关闭弹框刷新数据
const closeModal = () => {
  detailVisible.value = false
  getDataList()
}
//表格分页事件
const onPageChange = (data) => {
  page.value.page = data
  getDataList()
}
//表格每页条数事件
const sizeChange = (data) => {
  page.value.size = data.pageSize
  page.value.page = 1
  getDataList()
}
watch(locale, () => {
  console.log('123123')
})
//点击支付
const paymentClick = (payload) => {
  modalPayref.value.action(payload)
}
//tabs切换页面
const tabsChange = (e) => {
  console.log(e)
  columns.value = returnColumn(activeKey.value)
  getDataList()
}
watch(locale, () => {
  columns.value = returnColumn(activeKey.value)
})
/* 操作日志 */
function operationLogFn() {
  operationLogModalVisible.value = true
}
//点击查看日志
const showLogsClick = (objectId) => {
  //businessType: 业务类型（1：订单,2:退供应商订单）
  operationLogList({ objectId, businessType: 2 }).then((res) => {
    console.log(res, '获取日志成功')
    if (res.code == 200) {
      logsList.value = res.data
      logsVisible.value = true
    }
  })
}
const toDetail = (payload) => {
  detailVisible.value = true
  setTimeout(() => {
    detailRef.value.getDetail(payload.id)
    detailRef.value.getDetailList(payload.returnSupplierSn)
  }, 500)
}
onActivated(() => {
  route.meta.isBack = false
  route.meta.isEdit = false
})

// 选中的行key列表
const selectedRowKeysList = ref([])
// 选中的行数据
const selectedRowList = ref([])
const rowSelection = computed(() => {
  return {
    // getCheckboxProps: record => ({
    //   disabled: dataSource.value.some(it => it.attributeId == record.id),
    // }),
    fixed: true,
    selectedRowKeys: unref(selectedRowKeysList),
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys, selectedRows, 'selectedRowKeys, selectedRows------selectedRowKeys, selectedRows')
      selectedRowKeysList.value = selectedRowKeys
      selectedRowList.value = selectedRows
    },
    hideDefaultSelections: true,
  }
})

//点击导出订单明细
const downloadToken = ref() //导出明细token
const spinRef = ref(false)
const exportClick = async () => {
  downloadToken.value = ''
  let selelist = {
    returnSupplierSns: selectedRowKeysList.value.map(String),
  }
  let payload = {
    projectCode: 'SUPPLIER_RETURN_ORDER_DETAIL_EXPORT_NEW',
    dataParam: JSON.stringify(selelist),
  }
  const { code, data } = await createExportTask(payload)
  if (code == 200) downloadToken.value = data
  spinRef.value = true
  await getTaskResultFn(data)
}
const countNum = ref('')
//下载列表轮训
const getTaskResultFn = () => {
  getTaskResult({ taskToken: downloadToken.value })
    .then((res) => {
      if (res.code == 200) {
        // 任务状态
        //   0、待处理
        //   1、成功
        //   9、失败
        if (countNum.value > 5 && res.data.state == 0) {
          countNum.value = 0
          spinRef.value = false
          return message.error('下载失败，请稍后重试')
        }
        switch (res.data.state) {
          case 0:
            countNum.value++
            setTimeout(getTaskResultFn, 2000)
            break
          case 1:
            countNum.value = 0
            spinRef.value = false
            selectedRowKeysList.value = []
            selectedRowList.value = []
            res.data.fileUrl && (window.location.href = res.data.fileUrl)
            break
          case 9:
            countNum.value = 0
            spinRef.value = false
            message.error(res.data.resultMessage || '下载失败')
            break
          default:
            break
        }
      }
    })
    .catch(() => {
      countNum.value = 0
      spinRef.value = false
    })
}

//点击物流单号
const addressVisible = ref(false)
const logistics = ref([]) //物流轨迹数据
const viewWuliu = (item = '') => {
  console.log(item)
  expressLogsGet({
    number: item,
    // number: '773231117443193',
  }).then((res) => {
    logistics.value = res.data
    addressVisible.value = true
  })
}
//点击物流展示物流轨迹
const showAddressMOdal = (item) => {
  console.log(item, 'item-----item----123123')
  addressVisible.value = true
  viewWuliu(item)
}

defineExpose({ action })
</script>
<style lang="less">
::v-deep(.ant-drawer-content-wrapper) {
  width: 85% !important;
}
.addCode {
  text-decoration: underline;
  color: #ff6700;
  cursor: pointer;
}
.itemLogs {
  margin-top: 14px;
  color: #333;
  .drawTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.radiusBox {
  width: 14px;
  height: 14px;
  background: #f00;
  border-radius: 50%;
}
.drawBot {
  background: #ccc;
  padding: 10px 4px;
  border-radius: 2px;
}
.logsBtn {
  background: #1890ff;
  border: #1890ff;
}
.logsBtn:hover {
  background: #1890ff;
  border: #1890ff;
}
.logsBtn:active {
  background: #1890ff;
  border: #1890ff;
}
.logsBtn:focus {
  background: #1890ff;
  border: #1890ff;
}
.manageNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
