<template>
  <a-modal v-model:visible="visible" :title="modalTitle" :width="modalWidth" :maskClosable="false" :keyboard="false" :footer="null" :closable="modalClosable" class="CustomModal" :afterClose="afterClose" @cancel="cancel">
    <template #closeIcon>
      <slot name="modal-header">
        <img src="@/assets/supplier/images/modal-close-icon.png" style="width: 30px" />
      </slot>
    </template>
    <div class="modal-header">
      <slot name="modal-header" />
    </div>
    <div class="modal-body">
      <slot name="default">
        <div class="modal-body-title">{{ modalBodyTitle }}</div>
        <div class="modal-body-tips">{{ modalBodyTips }}</div>
      </slot>
    </div>
    <div class="modal-footer" :style="{ 'justify-content': modalFooterJustify }" v-if="modalFooterVisible">
      <slot name="modal-footer">
        <a-space :size="16">
          <div v-if="modalCancelVisible" class="form-item-button gray" @click="cancel">{{ modalCancelText || t('cancel') }}</div>
          <a-button type="primary" size="large" :loading="submitting" @click="handleOk">{{ modalOkText || t('confirm') }}</a-button>
        </a-space>
      </slot>
    </div>
  </a-modal>
</template>

<script>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  name: 'CustomModal',
  props: {
    modalWidth: {
      type: String,
      default: '500px',
    },
    modalVisible: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: '',
    },
    modalClosable: {
      type: Boolean,
      default: true,
    },
    modalBodyTitle: {
      type: String,
      default: '',
    },
    modalBodyTips: {
      type: String,
      default: '',
    },
    modalCancelVisible: {
      type: Boolean,
      default: false,
    },
    modalCancelText: {
      type: String,
      default: '',
    },
    modalOkText: {
      type: String,
      default: '',
    },
    modalFooterVisible: {
      type: Boolean,
      default: true,
    },
    modalFooterJustify: {
      type: String,
      default: 'flex-end',
    },
    submitting: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modalVisible', 'update:submitting', 'submit', 'cancel', 'afterClose'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const visible = ref(false);
    watch(
      () => props.modalVisible,
      (newValue) => {
        visible.value = newValue;
      }
    );
    function cancel() {
      if (props.submitting) return;
      emit('cancel');
      afterClose();
    }
    function handleOk() {
      emit('submit');
    }
    function afterClose() {
      emit('afterClose');
      emit('update:modalVisible', false);
      emit('update:submitting', false);
    }

    return {
      t,
      visible,
      cancel,
      handleOk,
      afterClose,
    };
  },
};
</script>

<style lang="less">
.CustomModal {
  .ant-modal-close-x {
    width: 80px;
    height: 62px;
    line-height: 70px;
  }
  .ant-modal-content {
    border-radius: 30px;
    overflow: hidden;
  }
  .ant-modal-header {
    padding-top: 24px;
    border-bottom: none;

    .ant-modal-title {
      color: #131011;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .ant-modal-body {
    padding-top: 0;
  }
  .modal-body {
    width: 100%;
    padding: 24px 0 40px;
    border-top: 1px solid #eee;
    .modal-body-title {
      color: #131011;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
    }
    .modal-body-tips {
      color: #131011;
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
      margin-top: 16px;
      text-align: center;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
